import { useState, useEffect } from 'react';
import { UilYoutube, UilEnvelope, UilPhone, UilWhatsapp, UilApps } from '@iconscout/react-unicons';
import {Link} from 'react-router-dom';
import logo from '../../../assets/img/logo/logo.png';

const Navbar = (props) => {
  // state for navbar click event
  const [isOpen, setIsOpen] = useState(false);

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.menu-item')) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  // Mobile Nav
  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };

  return (
    <nav className="navbar zyzics_company_banner">
      <Link to="/" id="logo">
        <img src={logo} style={{width: '50px', height: '50px' }} title="Zyzics Logo" alt="Zyzics Logo" />

        <h1>{props.project} IT Training <br />and Software Ltd.</h1>
      </Link>
      
      <li className="dvs-header__trigger" onClick={toggleMenu}><UilApps /></li>
      
      {/* style={{background: 'green'}} */}
      <ul className={`menu ${isOpen ? 'is-open' : ''}`}>
        <li className="menu-item" title="Click here to browse IT courses">
          <button
            onClick={() => toggleDropdown('featuresDropdown')}
            className={`menu-button ${openDropdown === 'featuresDropdown' ? 'active' : ''}`}
          >
            IT Training <span className={`caret ${openDropdown === 'featuresDropdown' ? 'rotate' : ''}`}>▼</span>
          </button>
          {openDropdown === 'featuresDropdown' && (
            <div className="dropdown">
              <div className="dropdown-angle"></div>
              <div className="dropdown-content">
                <ul className="">
                  <h6>Programming</h6>
                  <li><Link to="/">Java</Link></li>
                  <li><Link to="/">Python</Link></li>
                  <li><Link to="/">PHP</Link></li>
                  <li><Link to="/">JavaScript, C#</Link></li>

                  <br />
                  <h6>Frontend Web Developmt</h6>
                  <li><Link to="/">React.js</Link></li>
                  <li><Link to="/">HTML, CSS, JavaScript</Link></li>
                  
                  <br />
                  <h6>Backend Web Developmt</h6>
                  <li><Link to="/">Django, DRF</Link></li>
                  <li><Link to="/">Laravel</Link></li>
                  <li><Link to="/">Node.js, Spring Boot</Link></li>
                  <li><Link to="/">REST API, Microserv.</Link></li>
                
                </ul>
                <ul>
                  <h6>Full-Stack Web Developmt</h6>
                  <li><Link to="/">Django</Link></li>
                  <li><Link to="/">Laravel</Link></li>

                  <br />
                  <h6>Desktop App Developmt</h6>
                  <li><Link to="/">Swing, Tkinter, Kivy</Link></li>

                  <br />
                  <h6>Database Administration</h6>
                    <li><Link to="/">MySQL, PostgreSQL, MongoDB</Link></li>
                </ul>
                <div className="features">
                  <h6>Training Types</h6>
                  <ul className="footer__links">
                      <li><Link to="/">Corporate </Link></li>
                      <li><Link to="/">Private/Executive</Link></li>
                  </ul>

                  <h6>Course Delivery</h6>
                  <ul className="footer__links">
                      <li><Link to="/">Live Online Class</Link></li>
                      <li><Link to="/">Self-Paced Study</Link></li>
                      <li><Link to="/">On-Campus Class</Link></li>
                  </ul>

                  <br />
                  <h6>Resources</h6>
                  <ul>
                    <li><Link to="/">Books</Link></li>
                    <li><Link to="/">Study Guides</Link></li>
                    <li><Link to="/">Other Resources</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </li>
        <li className="menu-item" title="Click here for web design, software development, etc. services">
          <button onClick={() => toggleDropdown('whyDropdown')} className="menu-button">
            Software Development <span className={`caret ${openDropdown === 'whyDropdown' ? 'rotate' : ''}`}>▼</span>
          </button>
          {openDropdown === 'whyDropdown' && (
            <div className="dropdown">
              <div className="dropdown-angle"></div>
                <div className="dropdown-content">
                  <ul className="footer__links">
                    <h6>Website Design</h6>
                    <li><Link to="/">Website Design, Re-design</Link></li>   
                    <li><Link to="/">Domain, Hosting, SEO</Link></li>

                    <br />
                    <h6>Software Development</h6>
                    <li><Link to="/">Software Engineering</Link></li>
                    <li><Link to="/">Custom Software Dev</Link></li>
                    <li><Link to="/">Web Services &amp; APIs</Link></li>

                    <br />
                    <h6>Web Development</h6>
                    <li><Link to="/">Full Stack Development</Link></li>
                    <li><Link to="/">Backend Development</Link></li>
                    <li><Link to="/">Frontend Development</Link></li>
                  </ul>

                  <ul>
                    <h6>Mobile and Desktop Dev</h6>
                    <li><Link to="/">Mobile App Development</Link></li>
                    <li><Link to="/">Desktop App Dev</Link></li>
                    
                    <br />
                    <h6>Add-on Services</h6>
                    <li><Link to="/">Search Optimizatn (SEO)</Link></li>
                    <li><Link to="/">Content Managemt (CMS)</Link></li>
                    <li><Link to="/">Hosting / Domain</Link></li>
                    <li><Link to="/">Database Admin</Link></li>
                    <li><Link to="/">Long-term Maintenance</Link></li>
                    <li><Link to="/">IT Consulting</Link></li>

                    <br />
                    <h6>Our Apps</h6>
                    <li><Link to="/">School Software</Link></li>
                  </ul>
              </div>
            </div>
          )}
        </li>
        {/* <li className="menu-item" title="Click here for more links">
          <button onClick={() => toggleDropdown('resourcesDropdown')} className="menu-button">
            More <span className={`caret ${openDropdown === 'resourcesDropdown' ? 'rotate' : ''}`}>▼</span>
          </button>
          {openDropdown === 'resourcesDropdown' && (
            <div className="dropdown">
              <div className="dropdown-angle"></div>
              <div className="dropdown-content">
                <p>Books and Resources</p>
              </div>
            </div>
          )}
        </li> */}

        {/* style={{background: 'red'}} */}
        <div className="contacts" >
          <div>
            <a href="https://wa.me/message/GMNIQ5YNLZRFD1" className="zyzics__social-link" title="Chat with us on WhatsApp"><UilWhatsapp /></a>
            <a href="" className="zyzics__social-link" title="Send us an email"><UilEnvelope /></a>
            <a href="https://www.youtube.com/@zyzics-it-training" className="zyzics__social-link" title="Watch tutorials on our YouTube channel"><UilYoutube /></a>
            
            {/* <a href="https://linkedin.com/company/zyzics" className="zyzics__social-link"><UilLinkedin /></a> */}
          </div> 
          <div>
            <a href="tel:+2348021075169" style={{color: '#222'}} title="Click here to call us now">
              <h6><UilPhone /> +234 802-107-5169</h6>
            </a>
          </div>       
        </div>   
      </ul>
    </nav>
  );
};
export default Navbar;
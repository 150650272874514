import { useEffect, useRef } from 'react';
import Header from '../__public/__layouts/Header';
import { Helmet } from 'react-helmet';
import Banner from '../__public/__sections/Banner';
import CompanyBanner from '../__public/__sections/zCompanyBanner';
import Training_Courses from '../__public/__sections/Training_Courses';
import Training_Categories from '../__public/__sections/Training_Categories';
import Nav from '../__public/__layouts/zNav';
import DropdownMenu from '../layouts/DropdownMenu';
import Navbar from '../__public/__layouts/Navbar';
import Footer from '../__public/__layouts/Footer';

const Home = (props) => {
  useEffect(() => {
    document.title = `${props.project} IT Training and Software Company`;
  }, []);

  return (
    <>
    <Helmet>
      <link href="/reg/css/bootstrap.min.css" rel="stylesheet" />
    </Helmet>
    
    <Header />
    <Navbar project={props.project} />
    
    {/* <DropdownMenu /> */}

    {/* <Nav project={props.project} /> */}

    {/* <CompanyBanner project={props.project} company={props.company} /> */}
    <Banner />

    <Training_Categories /> 
    <Training_Courses />

    {/* <Footer project={props.project} company={props.company} /> */}
  </>
  );
}

export default Home;